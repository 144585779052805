import React from "react"
import ProjectStudyEpic from "../project/projectStudyEpic"
import ProjectStudyFeature from "../project/projectStudyFeature"
import ProjectStudyStory from "../project/projectStudyStory"
import { ProjectStudyStoryPoint } from "../project/projectStudyStory"
import { StaticImage } from "gatsby-plugin-image"
import ProjectRecommendation from "../project/projectRecommendation"

const OutcomeSurveys = () => {
  return (
  <ProjectStudyFeature
    id="surveys"
    title="Surveys"
    subtitle="The surveys feature enables our customers to define the content of versioned surveys, being able to revise each survey on the fly and set which survey version is active. They can also view results and response rate of each survey, being able to drill down to each individual response and all the context and details around it."
    content={<>
      <ProjectStudyStory
        previewPosition="carouselOnly"
        cardStyle="tertiaryCell"
        textStyle=""
        title=""
        subtitle=""
        images="imageCarousel"
        imageStyle=""
        carouselContent={<>
          <div className="studyStoryImage">
            <StaticImage
              src="../../images/cuehit/surveysOutcomeSurveysCarousel1.png"
              placeholder="blurred"
              layout="constrained"
              height={596}
              quality={100}
              alt="A CueHit mockup."
            />
          </div>

          <div className="studyStoryImage">
            <StaticImage
              src="../../images/cuehit/surveysOutcomeSurveysCarousel2.png"
              placeholder="blurred"
              layout="constrained"
              height={596}
              quality={100}
              alt="A CueHit mockup."
            />
          </div>

          <div className="studyStoryImage">
            <StaticImage
              src="../../images/cuehit/surveysOutcomeSurveysCarousel3.png"
              placeholder="blurred"
              layout="constrained"
              height={596}
              quality={100}
              alt="A CueHit mockup."
            />
          </div>

          <div className="studyStoryImage">
            <StaticImage
              src="../../images/cuehit/surveysOutcomeSurveysCarousel4.png"
              placeholder="blurred"
              layout="constrained"
              height={596}
              quality={100}
              alt="A CueHit mockup."
            />
          </div>
        </>}
      />

      <ProjectStudyStory
        previewPosition=""
        cardStyle="tertiaryCell"
        textStyle=""
        title=""
        subtitle={<span>
          When defining a survey, the user can specify header to provide a personalized introduction and context, define each question which can be a freeform text, range, or a multiple choice, and footer to for example thank people for taking the survey and provide them with more resources.
          <br /> <br />
          Thanks to an endless feedback loop, our customers had direct influence how the surveys feature was designed and worked.
        </span>}
        images="singleImage"
        imageStyle=""
        image1={<StaticImage
          src="../../images/cuehit/surveysOutcomeSurveysEdit.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A CueHit mockup."/>}
      />
    </>}
  />
  )
}

const OutcomeRules = () => {
  return (
  <ProjectStudyFeature
    id="rules"
    title="Rules Engine"
    subtitle={<span>
      To give full control of how surveys and other content was sent, we have built a powerful rules engine which connects to each organization's CAD (Computer Aided Dispatch), align with their specific data model and pull the latest data on encounters.
      <br /> <br />
      Based on the organization's data model, the user is able to define filters (such as caller city, incident type, time call closed), specify quiet hours when the survey was not sent, select a delay time, and finally specify targets (surveys and miscellaneous messages such as follow up text, ETA notifications, etc.) to ensure that their messages and surveys are sent to the right audience at the right time.
    </span>}
    content={<>
      <ProjectStudyStory
        previewPosition=""
        cardStyle="plainCell"
        textStyle=""
        title=""
        subtitle=""
        images="imageOnly"
        imageStyle=""
        image1={<StaticImage
          src="../../images/cuehit/surveysOutcomeRules1.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A CueHit mockup."/>}
      />

      <ProjectStudyStory
        previewPosition="previewOnLeft"
        cardStyle="tertiaryCell"
        textStyle=""
        title=""
        subtitle={<span>
          Each rule has versioning system just like surveys, and the user can test run the rule to ensure their rule works as expected before it's active and starts sending messages and surveys to their audiences.
          <br /> <br />
          Thanks to an endless feedback loop, our customers had direct influence how the rules feature was designed and worked.
        </span>}
        images="singleImage"
        imageStyle=""
        image1={<StaticImage
          src="../../images/cuehit/surveysOutcomeRules2.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A CueHit mockup."/>}
      />
    </>}
  />
  )
}

const OutcomeConversations = () => {
  return (
  <ProjectStudyFeature
    id="conversations"
    title="Conversations"
    subtitle={<span>
      The Conversations feature was designed to not just cater for the surveys use case, but to also enable our customers to communicate with their audience, triage conversations based on custom defined status and priority, and get the best context possible around each conversation.
      <br /> <br />
      Responders can see all of the conversations at glance sorted by status. If they are part of certain response group, they only see conversations relevant to that specific groups, but if they are members of multiple response groups or are given administrative role, they can seamlessly filter between all the response groups or see all the conversations at once. To easily find specific conversations, the responder can search through the messages. They can also start a new conversation by entering a cell phone number.
      <br /> <br />
      Each conversation preview contains the caller name, preview of the last message, priority tag and a count of new messages or followup reminder label.
    </span>}
    content={<>
      <ProjectStudyStory
        previewPosition=""
        cardStyle="plainCell"
        textStyle=""
        title=""
        subtitle=""
        images="imageOnly"
        imageStyle=""
        image1={<StaticImage
          src="../../images/cuehit/surveysOutcomeConversations1.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A CueHit mockup."/>}
      />

      <ProjectStudyStory
        previewPosition="previewOnLeft"
        cardStyle="tertiaryCell"
        textStyle=""
        title=""
        subtitle="When viewing a conversation, the responder can see at glance all the messages exchanged between the caller and anyone on the platform, including the chatbot. They can request a video call (via our custom ad hoc video chat interface over the web with no app needed), refer the caller to alternative response resources (such as referring to rideshare, coordinating an urgent care appointment, scheduling a tele-consult with a nurse, arrange for a private ambulance or refer to ready responder group), request location (via our custom web portal - no account or app is needed), or share a file (such as PDF or an image)."
        images="singleImage"
        imageStyle=""
        image1={<StaticImage
          src="../../images/cuehit/surveysOutcomeConversations2.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A CueHit mockup."/>}
      />

      <ProjectStudyStory
        previewPosition="previewOnRight"
        cardStyle="tertiaryCell"
        textStyle=""
        title=""
        subtitle="Each conversation has as much context as we can possibly provide and varies depending on the agency's system. For example, we can display the caller's name such as name and encounter location, pre-existing conditions and insurance details. The responder can also change the conversation status based on their specific workflow, set the priority tag, see how long the conversation has been open for, and set a follow-up reminder. They can also add their own notes to each conversation and view a list of all the files and images at a glance."
        images="singleImage"
        imageStyle=""
        image1={<StaticImage
          src="../../images/cuehit/surveysOutcomeConversations3.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A CueHit mockup."/>}
      />
    </>}
  />
  )
}

const OutcomeInsights = () => {
  return (
  <ProjectStudyFeature
    id="insights"
    title="Insights"
    subtitle={<span>
      Our customers needed a powerful way to perform in-depth analysis to better understand the quality of service they provide, and expected to be able to filter through their data. Each agency had their specific requirement and variants of their own data set. As a result, we've integrated with Amazon QuickSight (AWS GovCloud) to provide them with a fast business intelligence service that enabled them to deliver insights to everyone in their agency.
      <br /> <br />
      In addition, we’ve developed an activity dashboard to give chiefs and managers an overview of all the activities on our system. All of the most important data is available at a glance - starting with today’s insights which displays survey answer ratio, the amount of rules trigerred and surveys sent out for that specific day, as well as the top rules and top encounter types. The admin can drill out and see surveys that have been marked as pending review, and also review sent surveys and view all the encounters and whenever a rule matched each specific encounter.
    </span>}
    content={<>
      <ProjectStudyStory
        previewPosition=""
        cardStyle="plainCell"
        textStyle=""
        title=""
        subtitle=""
        images="imageOnly"
        imageStyle=""
        image1={<StaticImage
          src="../../images/cuehit/surveysOutcomeInsights.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A CueHit mockup."/>}
      />
    </>}
  />
  )
}

const OutcomeFeedback = () => {
  return (
  <ProjectStudyFeature
    id="feedback"
    title="Feedback Board"
    subtitle={<span>
      More than 80% of call-takers and first responders experience trauma in their job. Unbeknownst to the public, first responder suicide rates are trending upwards despite national rates decreasing. We wanted to make an impact, and help and bring some positivity to all of the heroes who serve and protect the US citizens.
      <br /> <br />
      We've noticed a trend where folks have a 'positivity board' in their offices to boost morale and encourage them to keep doing great job, and wanted to help.
      <br /> <br />
      A ML model was developed to give each survey response a sentiment. The administrator will then review survey responses with positive sentiments and approve them to be shown on a list with personally identifiable data filtered out, which anyone at the agency can view.
    </span>}
    content={<>
      <ProjectStudyStory
        previewPosition=""
        cardStyle="plainCell"
        textStyle=""
        title=""
        subtitle=""
        images="imageOnly"
        imageStyle=""
        image1={<StaticImage
          src="../../images/cuehit/surveysOutcomeFeedback1.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A CueHit mockup."/>}
      />

      <ProjectStudyStory
        previewPosition=""
        cardStyle="secondaryCell"
        textStyle=""
        title="The feedback we've received was amazing. Responders loved the feedback board, and wanted to have a dedicated screen for everyone to see. So we've created a massive UI with positive feedback messages being cycled through - optimized for large screens in the middle of the room that everyone can see and hopefully makes them see the positive impact they have on the community."
        subtitle=""
        images="noImages"
        imageStyle=""
      />

      <ProjectStudyStory
        previewPosition=""
        cardStyle="plainCell"
        textStyle=""
        title=""
        subtitle=""
        images="imageOnly"
        imageStyle=""
        image1={<StaticImage
          src="../../images/cuehit/surveysOutcomeFeedback2.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A CueHit mockup."/>}
      />
    </>}
  />
  )
}

const OutcomeResults = () => {
  return (
    <ProjectStudyFeature
      id="results"
      title="Results"
      subtitle=""
      content={
      <ProjectStudyStory
        previewPosition=""
        cardStyle="primaryCell"
        title=""
        subtitle={<>
          <span>After shipping and iterating on the satisfaction surveys, we're seeing a lot of positive impact.</span>
          <ProjectStudyStoryPoint icon="check" text="Our customers are reporting high response rate, some seeing 50% response rate - a 9x increase compared to before using CueHit!" />
          <ProjectStudyStoryPoint icon="check" text="Responders are now receiving more relevant and fast feedback." />
          <ProjectStudyStoryPoint icon="check" text="Police chiefs are utilizing our tools to build better relationship between the public." />
          <ProjectStudyStoryPoint icon="check" text="Counties with CueHit are seeing better outcomes." />
          <ProjectStudyStoryPoint icon="check" text="Feedback Board is one of the most loved features increasing morale and bettering mental health of responders." />
          <ProjectStudyStoryPoint icon="check" text="Agencies are getting better and deeper insights, enabling them to improve their service and follow up with the citizens." />
          <ProjectStudyStoryPoint icon="check" text="In less than a year, we've deployed CueHit to 50+ paying customers ranging from police departments to 911 call centers." />
          <ProjectStudyStoryPoint icon="check" text="CueHit partnered with multiple agencies, including Union County PD and 9-1-1 in New Jersey to introduce a new era of community engagement for public safety." />
        </>}
        images="noImages"/>}
    />
  )
}

const CueHitOutcome = () => {
  return (
  <ProjectStudyEpic
    title="Outcome"
    subtitle=""
    content={<>
      <OutcomeSurveys />
      <OutcomeRules />
      <OutcomeConversations />
      <OutcomeInsights />
      <OutcomeFeedback />
      <OutcomeResults />

      <ProjectRecommendation
        image={<StaticImage
          src="../../images/recommendations/ScottMacDonald.jpg"
          placeholder="blurred"
          layout="constrained"
          quality={95}
          alt="Scott MacDonald"/>}
        name="Scott MacDonald"
        title="CEO & Cofounder of CueHit"
        quote="With a passion for design and a commitment to the highest quality experiences, Phil Amour was instrumental in bringing our startup from concept to market adoption in record time. As one of our founding team members at CueHit, Phil demonstrated a highly creative and intuitive sense of design - in all areas of our product and even our brand and marketing assets. He often developed design concepts from scratch and quickly learned a new demographic of users. Phil directly contributed to our rapid success and would be an asset to any team looking for the best in the field."
      />
    </>}
  />
  )
}

export default CueHitOutcome