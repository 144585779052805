import React from "react"
import ProjectStudyEpic from "../project/projectStudyEpic"
import ProjectStudyStory from "../project/projectStudyStory"
import { StaticImage } from "gatsby-plugin-image"

const CueHitSituation = () => {
  return (
  <ProjectStudyEpic
    title="Situation"
    subtitle=""
    content={
    <div className="epicRow">
      <div className="subRowColumn">
        <ProjectStudyStory
          previewPosition=""
          cardStyle="primaryCell"
          title="Audience"
          subtitle="Our goal was to improve lives of the general public, as well as first responders - ranging from 911 call-takers, law enforcement, to fire/ems."
          images="noImages"
        />

        <ProjectStudyStory
          previewPosition=""
          cardStyle="secondaryCell"
          title="The Problem"
          subtitle={<>
            When a member of the public (the caller) encounters an emergency, they will call 911 call center and generally stay on the line until the emergency is resolved.
            <br /><br />
            The 911 call-takers have minimal context when the call starts, and have to ask many questions as well as keep the caller up-to-date along the way with any advice and updates on arrivals as well as to relay any information to law enforcement, fire or ems. All of this happens under immense stress, and more than 80% of call-takers and first responders experience trauma in their job.
            <br /><br />
            Once the emergency is resolved, there’s very few ways to gather feedback from the caller - traditionally this is done by blindly sending surveys via a regular postal service to a few members of the public, resulting in poor response rate - of around 5%, and lacking much context. Additionally, there’s lack of follow-up advice or resource being shared by the call-takers who have the most information about each encounter.
          </>}
          images="noImages"
        />
      </div>

      <div className="subRowColumn">
        <ProjectStudyStory
          previewPosition="cover"
          cardStyle="primaryCell"
          title=""
          subtitle=""
          images="imageOnly"
          image1={<StaticImage
            src="../../images/cuehit/surveysSituation.jpg"
            placeholder="blurred"
            layout="constrained"
            quality={95}
            alt="A CueHit mockup."/>}
        />
      </div>
    </div>
    }
  />
  )
}

export default CueHitSituation