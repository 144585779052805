import React from "react"
import ProjectStudyEpic from "../project/projectStudyEpic"
import ProjectStudyStory from "../project/projectStudyStory"
import { StaticImage } from "gatsby-plugin-image"

const CueHitApproach = () => {
  return (
  <ProjectStudyEpic
    title="Approach"
    subtitle=""
    content={
    <div className="epicColumn">
    <div className="epicRow">
      <div className="subRowColumn">
        <ProjectStudyStory
          previewPosition=""
          cardStyle="primaryCell"
          title=""
          subtitle={<>
            As a head of design and front-end developer, I was responsible for the end-to-end design process - from working with other co-founders on understanding the problems we wanted to solve to defining and developing the solutions.
            <br /><br />
            To begin with, we embarked on the journey of discovery. The co-founders were public safety technology veterans and former first responders with an immense network which we’ve reached out to and conducted interviews with many police chiefs, officers, 911 call-takers and 911 call center managers to further understand their workflows and painpoints.
            <br /><br />
            We’ve also identified a couple of specialized competitors that some of; however, none of them were tackling the the full end-to-end workflow our potential customers were looking for.
            <br /><br />
            After going through all the learnings from our research, we’ve brainstormed plenty of potential features and organized them in 3 key epics.
          </>}
          images="noImages"
        />

        <ProjectStudyStory
          previewPosition=""
          cardStyle="secondaryCell"
          title="Respond"
          subtitle="First responders need an easy and efficient way to communicate with the callers, getting as much of context as possible, request location, have an ad-hoc video call, as well as triage conversations and keep things organized based on response groups based on the team and role."
          images="noImages"
        />
      </div>

      <div className="subRowColumn">
        <ProjectStudyStory
          previewPosition="cover"
          cardStyle="primaryCell"
          title=""
          subtitle=""
          images="imageOnly"
          image1={<StaticImage
            src="../../images/cuehit/surveysApproach.jpg"
            placeholder="blurred"
            layout="constrained"
            quality={95}
            alt="A CueHit mockup."/>}
        />

        <ProjectStudyStory
          previewPosition=""
          cardStyle="secondaryCell"
          title="Engage"
          subtitle={<span>
            It's essential to our customers to connect and engage with the community, send automated updates and followups, and define surveys to be send based on specific encounters and many other variables. In addition, chiefs and managers expect to have visibility into how the system is working and what's happening behind the scenes.
            <br /> <br />
            We've also brainstormed an idea to improve morale and wellbeing by sharing positive feedback with all teams.
          </span>}
          images="noImages"
        />

        <ProjectStudyStory
          previewPosition=""
          cardStyle="secondaryCell"
          title="Measure"
          subtitle="To close the feedback loop and see the results of all the responders' hard work, chiefs and managers needed a way to perform an in-depth analysis to better understand the quality of service they provide and identify any issues."
          images="noImages"
        />
      </div>
    </div>

    <ProjectStudyStory
      previewPosition=""
      cardStyle="primaryCell"
      title="The Process"
      subtitle="Once each user story was clearly defined, I begin designing possible solutions starting with conversations and surveys, constantly gathering feedback from co-founders and our potential customers, iterating until we were confident our designs would solve our customers' problems, and then I finally moved to implementing UI and layout in React & SASS (CSS) in well structured and componetized approach - working closely with the co-founder who implemented the backend and made the UI I implemented functional show real data."
      images="noImages"
    />
    </div>}
  />
  )
}

export default CueHitApproach