import * as React from "react"

const CueHitSummary = () => {
  return (
  <section className="mercurySection summarySection studySummary">
    <div className="sectionContent">
      <div className="studySummaryText">
        <h2 className="studySummaryTitle">Satisfaction Surveys</h2>
        <p className="studySummarySubtitle">Transforming connections and communication between the American public and first responders by creating a modern solution that revolutionizes how public agencies respond, engage, and get insights on public safety - leading to better outcomes for all.</p>
      </div>
    </div>
  </section>
  )
}

export default CueHitSummary
