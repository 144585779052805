import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ProjectAttribute from "../project/projectAttribute"
import ProjectDetail from "../project/projectDetail"

const CueHitDetails = () => {
  return (
    <ProjectDetail
      projectIcon={
        <StaticImage
          src="../../images/imageProjectPreviewIconCueHit.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A CueHit logo."
        />
      }
      projectName="CueHit"
      projectOwner="CueHit, Inc."
      projectButtonLink="https://cuehit-old.netlify.app/"
      projectButtonTitle="See it Live"
      projectButtonType="dual"
      projectSecondButtonLink="https://blog.neogov.com/press/2022-agency360-cuehit-planit"
      projectSecondButtonTitle="Acquired"
      projectDescription="CueHit was started by Public Safety Technology veterans in order to provide a continuous feedback loop between agencies and the communities they serve. Our solution was built on a rules-based, intelligent assistant that enables text exchanges for satisfaction surveys after encounters, automatic updates to citizens via text and inbound “bot-attended” chat for feedback, tips, requests, reports and more."
      projectSolutions={<div>
        <ProjectAttribute
          title="SMS Surveys"
          detail="Automatic text surveys sent a few hours or a few days after 9-1-1 calls to measure satisfaction of callers for non-critical incidents."
        />

        <ProjectAttribute
          title="Feedback Board"
          detail="Improving staff morale with positive citizen feedback. Dynamic positive feedback board for display throughout the department."
        />

        <ProjectAttribute
          title="Data Analytics and Dashboards"
          detail="Enabling departments to analyze response rates to surveys over time to measure engagement. Many 9-1-1 centers are seeing greater than 50% response rates!"
        />

        <ProjectAttribute
          title="Automatic Email Digests"
          detail="Automatic email delivery of personal satisfaction score to officers with summary of positive comments received from citizens."
        />

        <ProjectAttribute
          title="And much more!"
          detail="Chatbot Experience, Automation Rules, Performance Email Digest, Follow-Up Tasks, Video Chat, Telehealth, Patient Details and Insurance Insights, Messaging Experience, Triage and Personnel Assignment, Marketing Site, Team & Role Experience and Management"
        />
      </div>}
      projectRole="Cofounder & Head of Design"
      projectDuration="2019-2021"
      projectSummary="Designed and built the front-end of a SaaS targeted at 911 call centers, law enforcement, EMS, and fire agencies that reshapes citizen connections for public safety. Partnered with Union County PD and 9-1-1 in New Jersey to introduce a new era of community engagement for public safety. Thanks to our innovative solution, the NJ department is seeing a 50% response rate for post-incident surveys vs less than five percent before. Designed and built the front-end of many innovative features - surveys, automated rules, communication tools (text/email/video calls/chatbots), analytics, internal mobile app, etc. After partnering with 50+ paying customers ranging from police departments to 911 call centers and incurring 1M+ in ARR, CueHit has been acquired by NEOGOV."
      projectAttributes={<div>
        <ProjectAttribute
          title="Design"
          detail="End-to-End Product Design, UI Design, User Research, Interaction Design, High-Fidelity Prototyping, Usability Testing, Figma"
        />

        <ProjectAttribute
          title="Front-End Development"
          detail="React.js, Gatsby.js, CSS/SASS, UI Architecture"
        />

        <ProjectAttribute
          title="Platforms"
          detail="Web (Desktop), Mobile (Cross-Platform)"
        />

        <ProjectAttribute
          title="Business"
          detail="Public Safety, SaaS, B2B"
        />
      </div>}
    />
  )
}

export default CueHitDetails