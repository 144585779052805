import * as React from "react"
import Seo from "../../components/seo"
import "../../styles/styles.sass"
import Header from "../../components/header"
import Footer from "../../components/footer"
import CueHitDetails from "/src/views/cuehit/cuehitDetails"
import CueHitSummary from "/src/views/cuehit/cuehitSummary"
import CueHitSituation from "/src/views/cuehit/cuehitSituation"
import CueHitApproach from "/src/views/cuehit/cuehitApproach"
import CueHitOutcome from "/src/views/cuehit/cuehitOutcome"
import HireBanner from "../../views/hire/hireBanner"

const CueHitPage = () => {
  return (
  <main className="mercuryView projectView projectCueHit">
    <Seo
      title="Phil Amour — CueHit"
      description="Read my case study on CueHit to learn about my role and approach to product design."
      image="/imageSEOCueHit.jpg"
    />
    
    <Header headerType="detail" />

    <article className="mercuryContent">
      <CueHitDetails />

      <CueHitSummary />

      <CueHitSituation />

      <CueHitApproach />

      <CueHitOutcome />

      <section className="mercurySection contactSection">
        <HireBanner
          bannerTitle="Like the work I've done with CueHit?"
          bannerSubtitle=""
        />
      </section>
    </article>
    
    <Footer />
  </main>
  )
}

export default CueHitPage
